<template>
<div>
  <router-view/>
</div>
</template>

<script>
export default {
  name: "vieww"
}
</script>

<style scoped>

</style>